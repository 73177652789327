import { Flex, Icon, Box, Link, Image } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IoCaretDownSharp } from "react-icons/io5";
import { ShoppingCart } from "./ShoppingCart";
import { UserMenu } from "./UserMenu";
import bsLogo from "../../../../../assets/bs-logo-new.jpg";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTags } from "@/modules/products";

export function MobileHeader() {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  const { resetFilter } = useTags();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setIsMenuOpen(false),
  });

  return (
    <Flex
      display={{ base: "flex", lg: "none" }}
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      p={4}
      boxShadow="sm"
      ref={ref}
    >
      <Flex w="full" justifyContent="space-between" alignItems="center">
        <Flex
          w="26px"
          h="22px"
          flexDir="column"
          justifyContent="space-between"
          onClick={() => setIsMenuOpen(prev => !prev)}
          _hover={{ cursor: "pointer" }}
        >
          <Box
            h="2px"
            w="full"
            bg="bsAccent"
            transformOrigin="left"
            transition="transform .3s ease"
            transform="scaleX(1)"
            borderRadius="full"
          ></Box>
          <Box
            h="2px"
            w="full"
            bg="bsAccent"
            transformOrigin="left"
            transition="transform .3s ease"
            transform={isMenuOpen ? "scaleX(0.7)" : "scaleX(1)"}
            borderRadius="full"
          ></Box>
          <Box
            h="2px"
            w="full"
            bg="bsAccent"
            transformOrigin="left"
            transition="transform .3s ease"
            transform={isMenuOpen ? "scaleX(0.3)" : "scaleX(1)"}
            borderRadius="full"
          ></Box>
        </Flex>
        <Link as={RouterLink} to="/">
          <Image
            src={bsLogo}
            alt="Beauty District Logo"
            h="28px"
            w={{ base: "180px", md: "full" }}
            objectFit="contain"
            onClick={() => {
              resetFilter();
              navigate("/");
            }}
          />
        </Link>
        <Flex>
          <UserMenu />
          <ShoppingCart />
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        overflowY="hidden"
        maxHeight={isMenuOpen ? "800px" : "0px"}
        transition="max-height .3s ease"
        position="absolute"
        pb={isMenuOpen ? 8 : 0}
        top="100%"
        left="0"
        width="100%"
        bg="white"
        boxShadow="sm"
        zIndex={2}
        color="bsAccent"
        gap={8}
      >
        <Link
          href={process.env.VITE_BD_WEBSITE_URL}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Home
        </Link>
        <Link
          as={RouterLink}
          to="/"
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Shop
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}vacatures/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Vacatures
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}merken/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Onze merken
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}nieuws/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Nieuws
        </Link>
        <Link mx={5} _hover={{ textDecor: "none", color: "bsBlack" }}>
          Educatie <Icon as={IoCaretDownSharp} w={3} mb={-0.5} />
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}educatie/`}
          ml={10}
          mr={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Beautydistrict L.A.B.
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}agenda/`}
          ml={10}
          mr={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Agenda trainingen
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}over-ons/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Over ons
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}klant-worden/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Klant worden
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}contact/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Contact
        </Link>
      </Flex>
    </Flex>
  );
}
