import {
  Flex,
  HStack,
  Menu,
  MenuButton,
  Button,
  Icon,
  MenuList,
  MenuItem,
  Link,
  Image,
} from "@chakra-ui/react";
import { IoCaretDownSharp } from "react-icons/io5";
import { ShoppingCart } from "./ShoppingCart";
import { UserMenu } from "./UserMenu";
import { Link as RouterLink } from "react-router-dom";

import facebook from "../../../../../assets/facebook.svg";
import instagram from "../../../../../assets/instagram.svg";
import linkedin from "../../../../../assets/linkedin.svg";
import bsLogo from "../../../../../assets/bs-logo-new.jpg";
import { useTags } from "@/modules/products";

export function DesktopHeader() {
  const { resetFilter } = useTags();

  return (
    <Flex
      display={{ base: "none", lg: "flex" }}
      flexDir="column"
      maxW="1248px"
      h="full"
      gap={1}
      px={4}
      py={6}
      mx="auto"
    >
      <Flex
        display="flex"
        justifyContent="space-between"
        px={0}
        alignItems="center"
        h="full"
        pb={{ base: "0", md: "3" }}
        flexWrap="wrap"
      >
        <HStack
          gap={4}
          display={{ base: "none", md: "flex" }}
          justifyContent="start"
        >
          <Link
            isExternal
            href={"https://www.instagram.com/beautydistrictlab/"}
          >
            <Image src={instagram} alt="instagram" h="24px" />
          </Link>
          <Link isExternal href={"https://www.facebook.com/BeautydistrictBV"}>
            <Image src={facebook} alt="facebook" h="24px" w="18px" />
          </Link>
          <Link
            isExternal
            href={"https://www.linkedin.com/company/beautydistrict-b-v-/"}
          >
            <Image src={linkedin} alt="linkedin" h="24px" />
          </Link>
        </HStack>

        <HStack color="#978984" display={{ base: "none", md: "block" }}>
          <Link as={RouterLink} to="/">
            <Image
              src={bsLogo}
              alt="Beauty District Logo"
              maxW="400px"
              objectFit="contain"
              onClick={() => {
                resetFilter();
              }}
            />
          </Link>
        </HStack>
        <HStack alignItems="flex-end" justifyContent="center" gap={0}>
          <UserMenu />
          <ShoppingCart />
        </HStack>
      </Flex>
      <Flex justifyContent="center" color="bsAccent" alignItems="center">
        <Link
          href={process.env.VITE_BD_WEBSITE_URL}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Home
        </Link>
        <Link
          as={RouterLink}
          to="/"
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Shop
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}vacatures/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Vacatures
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}merken/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Onze merken
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}nieuws/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Nieuws
        </Link>
        <Menu autoSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                rightIcon={<Icon as={IoCaretDownSharp} ml={-1} mt={0.5} />}
                variant="unstyled"
                fontWeight="normal"
                color="bsAccent"
                display="inline-flex"
                mx={5}
                _hover={{ textDecor: "none" }}
              >
                Educatie
              </MenuButton>
              <MenuList borderRadius="base" minW="fit-content">
                <MenuItem
                  onClick={() =>
                    (window.location.href = `${process.env.VITE_BD_WEBSITE_URL}educatie/`)
                  }
                  px={4}
                  _hover={{ bg: "#f5f5f5" }}
                >
                  Beautydistrict L.A.B.
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    (window.location.href = `${process.env.VITE_BD_WEBSITE_URL}agenda/`)
                  }
                  px={4}
                  _hover={{ bg: "#f5f5f5" }}
                >
                  Agenda trainingen
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}over-ons/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Over ons
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}klant-worden/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Klant worden
        </Link>
        <Link
          href={`${process.env.VITE_BD_WEBSITE_URL}contact/`}
          mx={5}
          _hover={{ textDecor: "none", color: "bsBlack" }}
        >
          Contact
        </Link>
      </Flex>
    </Flex>
  );
}
